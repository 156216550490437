/* FK Grotesk Neue - Normal */
@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-Bold.woff2') format('woff2');
}

/* FK Grotesk Neue - Italic */
@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-LightItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-Italic.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-MediumItalic.woff2') format('woff2');
}

@font-face {
	font-family: 'FK Grotesk Neue';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/FKGroteskNeue-BoldItalic.woff2') format('woff2');
}
