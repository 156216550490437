.introjs-helperLayer {
	border-radius: 8px !important;
	box-shadow:
		0px 0px 2px 2px #5647fd,
		0px 0px 2px 5000px rgba(15, 15, 15, 0.5) !important;
}

.introjs-tooltipReferenceLayer * {
	color: #0f0f0f;
	font-family: Inter !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.introjs-tooltip {
	border-radius: 8px !important;
	background: #ffffff !important;
	box-shadow:
		0px 0px 0.5px 0px rgba(0, 0, 0, 0.15),
		0px 3px 6px 0px rgba(0, 0, 0, 0.2) !important;

	.introjs-tooltip-header {
		padding: 24px 24px 8px 24px;

		.introjs-tooltip-title {
			color: #0f0f0f;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
		}

		.introjs-skipbutton {
			margin: 10px 8px 0 0;
		}
	}

	.introjs-tooltiptext {
		display: flex;
		padding: 4px 24px;
		align-items: flex-start;
		align-self: stretch;
	}

	.introjs-bullets {
		padding: 12px 24px;
	}

	.introjs-tooltipbuttons {
		.introjs-button {
			color: #5647fd;
			border-radius: 360px;
			border: 2px solid rgba(255, 255, 255, 0);
			background: #ecebff;
			font-weight: 500;
			text-shadow: initial;

			&:hover {
				background: #bab3fe;
			}

			&:focus {
				border: 2px solid #5647fd;
				box-shadow: initial;
			}

			&.introjs-disabled {
				opacity: 0.5;
                &:hover {
                    background: #ecebff;
                }
                &:focus {
                    border: 2px solid transparent;
                    box-shadow: initial;
                }
			}

			&.introjs-donebutton {
				color: #0c5238;
				background: #e9fbf0;
				&:hover {
					background: #9fefbc;
				}
			}
		}
	}
}
